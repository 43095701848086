<template>
    <Header active_menu="1"/>
    <div class="box_blue"></div>
    <img src="../assets/img/yewu.png" alt="" class="img_yewu flexjc">
    <div class="flexjc">
        <div class="content">
        <div class="weizhi"><span>{{ $t('您的位置：首页') }}</span> <span class="dayu">&gt;</span> <span>{{ $t('公司信息')
        }}</span>
            <span class="dayu">&gt;</span> <span class="co5">{{ $t('官员介绍') }}</span>
        </div>

        <div class="guanyuan_r flexjc">
            <div class="guanyuan_r_text">
                <div class="guanyuan_name fontS24 co33"> 藤崎 竜成</div>
                <div class="text_in " style="margin:20px 0">董事长兼CEO 北京外国语⼤学 毕业</div>
                <div class="text_in">
                    2018年 株式会社萬通 CEO（现任）<br>
                    2018年 北京伊藤商贸有限公司 董事长（现任）<br>
                    2021年 藤崎海南供应链有限公司 董事长（现任）<br>
                    2022年 傲可凡（海南）网络科技有限公司 董事长兼CEO（现任）</div>
            </div>
            <img src="../assets/img/guanyuan.png" alt="" class="guanyuan_r_img">
        </div>
        <div class="guanyuan_r flexjc">
            <div class="guanyuan_r_text">
                <div class="guanyuan_name fontS24 co33"> 村上 聡</div>
                <div class="text_in " style="margin:20px 0">监事 ⽇本中央⼤学毕业</div>
                <div class="text_in">
                    2017年 DAN consulting株式会社 董事（现任）<br>
                    2020年 株式会社美邦 董事长兼CEO（现任）<br>
                    2020年 株式会社FACT 董事长兼CEO（现任）<br>
                    2022年 株式会社GTL 董事（现任）<br>
                </div>
            </div>
            <img src="../assets/img/18.png" alt="" class="guanyuan_r_img">
            
        </div>
     <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{$t('企业集团类官网')}}</div>
    </div>
    </div>

</template>   

<script>
// import { Swiper, SwiperSlide } from "swiper/vue";
// import { toRaw } from '@vue/reactivity'
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.text_in {
    font-size: 14px;
    font-family: Source Han Sans CN;
    line-height: 27px;
    color: #999999;
    width:440px
}

.guanyuan_r_text {
    /* padding: 0px 14px 0 0px; */
    /* box-sizing: border-box; */
}

.guanyuan_r_img {
    width: 421px;
    height: 440px;
    margin-left: 334px;
}

.guanyuan_r {
    max-width: 78%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}



.guanyuan {
    background: #F5F6FB;
    padding-top: 100px;
}

.content {
    max-width: 80%;
    margin: 48px auto;

}

.img_yewu {
    max-width: 80%;
    margin: -190px auto 0;
}

.box_blue {
    max-width: 100%;
    height: 369px;

    background: #1F4A92;
}
@media screen and (max-width:1000px) {
    .box_blue{
        height: 150px;
    }
    .img_yewu{
        margin-top: -40px;
    }
    .content{
        margin-top: 20px;
    }
    .weizhi{
        font-size: 6px;
    }
    .guanyuan_r_img{
        margin-left: 0;
        width:40vw;
        height: 40vw;
    }
    .guanyuan_name{
        margin-top: 5px;
    }
    .text_in{
        width: 200px;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        font-size: 8px;
        line-height: 10px;
    }
    .COPY{
        font-size: 8px;
    }
}
</style>
